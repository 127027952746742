<script setup lang="ts">
import type { IntegratedDatabase } from '@/types/supabaseIntegrated'
import type { FilterDatabase } from '@/types/supabaseFilter'

const user = useSupabaseUser()
const client = useSupabaseClient()
const colorMode = useColorMode()

const { url } = useImageStorage()

const { filterIntegratedAlarmType } = storeToRefs(useFilterDataStore())
const {
  integratedAlarmData,
  integratedNotification,
  newOwnerMeetingAlarm,
  newCoachingMeetingAlarm,
  newRepairMeetingAlarm,
  newRescueMeetingAlarm,
  newPickupMeetingAlarm,
  newMeetingFeedbackAlarm,
  newGuestbookAlarm,
  newReceiveSparkAlarm,
  newReceivePointAlarm,
  newArticleCommentAlarm,
} = storeToRefs(useNotificationsStore())

const { executeIntegratedAlarm } = useLoadIntegratedAlarmData()

defineEmits([
  'click:menu-open',
])

const listenIntegratedAlarm = ref()

const checkIntegratedAlarm = (integratedAlarm: IntegratedDatabase['integrated']['Tables']['alarm']['Row'][]) => {
  if (!integratedAlarm.length) {
    return
  }

  integratedAlarm.forEach((alarm: IntegratedDatabase['integrated']['Tables']['alarm']['Row']) => {
    switchAlarm(alarm)
  })
}

const switchAlarm = (alarm: IntegratedDatabase['integrated']['Tables']['alarm']['Row']) => {
  switch (findIntegratedAlarmTypeCode(alarm?.alarm_type_id ?? '')) {
    case 'IAT001':
      if (!alarm.checked) {
        newOwnerMeetingAlarm.value = true
      }
      break
    case 'IAT002':
      if (!alarm.checked) {
        newCoachingMeetingAlarm.value = true
      }
      break
    case 'IAT003':
      if (!alarm.checked) {
        newRepairMeetingAlarm.value = true
      }
      break
    case 'IAT004':
      if (!alarm.checked) {
        newRescueMeetingAlarm.value = true
      }
      break
    case 'IAT005':
      if (!alarm.checked) {
        newPickupMeetingAlarm.value = true
      }
      break
    case 'IAT006':
      if (!alarm.checked) {
        newGuestbookAlarm.value = true
      }
      break
    case 'IAT007':
      if (!alarm.checked) {
        newReceiveSparkAlarm.value = true
      }
      break
    case 'IAT008':
      if (!alarm.checked) {
        newReceivePointAlarm.value = true
      }
      break
    case 'IAT009':
      if (!alarm.checked) {
        newMeetingFeedbackAlarm.value = true
      }
      break
    case 'IAT010':
      if (!alarm.checked) {
        newArticleCommentAlarm.value = true
      }
      break
  }
}

if (import.meta.client) {
  listenIntegratedAlarm.value = client
    .channel('integratedAlarm')
    .on('postgres_changes',
      { event: 'INSERT', schema: 'integrated', table: 'alarm', filter: `owner_user_id=eq.${user.value?.id ?? ''}` },
      (payload: { new: IntegratedDatabase['integrated']['Tables']['alarm']['Row'] }) => {
        switchAlarm(payload.new)
      },
    )
    .subscribe()

  const computedNewMeetingAlarm = computed(() => {
    return newOwnerMeetingAlarm.value
      || newCoachingMeetingAlarm.value
      || newRepairMeetingAlarm.value
      || newRescueMeetingAlarm.value
      || newPickupMeetingAlarm.value
  })

  const updateIntegratedNotification = () => {
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.updateIntegratedNotification) {
      window.webkit.messageHandlers.updateIntegratedNotification.postMessage({
        notificationKey: 'integratedNotification',
        notificationValue: integratedNotification.value ?? false,
      })
    }
  }

  onUnmounted(() => {
    if (user.value?.id) {
      listenIntegratedAlarm.value.unsubscribe()
    }
  })

  watchEffect(() => {
    integratedNotification.value = computedNewMeetingAlarm.value
      || newMeetingFeedbackAlarm.value
      || newGuestbookAlarm.value
      || newReceiveSparkAlarm.value
      || newReceivePointAlarm.value
      || newArticleCommentAlarm.value

    updateIntegratedNotification()
  })
}

await executeIntegratedAlarm()

onMounted(async () => {
  if (integratedAlarmData.value?.length) {
    checkIntegratedAlarm(integratedAlarmData.value)
  }
})

const findIntegratedAlarmTypeCode = (alarmTypeId: string) => {
  return filterIntegratedAlarmType.value?.find((alarmType: FilterDatabase['filter']['Tables']['integratedAlarmType']['Row']) => alarmType.id === alarmTypeId)?.code
}
</script>

<template>
  <div class="h-fit flex items-center gap-4 mt-5 px-5 pb-3 border-b border-gray03Light dark:border-gray03Dark">
    <ClientOnly>
      <AButton
        custom-class="p-0"
        button-variant="ghost"
        button-color="black"
        use-leading
        :image-url="url(true, `/assets/icon/${colorMode.value === 'light' ? 'menu' : 'menu_dark'}.svg`)"
        :image-size="24"
        @click="() => $emit('click:menu-open')"
      />
    </ClientOnly>
    <NuxtImg
      class="cursor-pointer"
      :src="url(true, '/assets/logo/wnm_logo.svg')"
      :width="32"
      @click="navigateTo('/')"
    />
    <div class="flex-auto" />
    <ClientOnly>
      <AChipButton
        v-if="user?.id"
        button-custom-class="p-0"
        :enable-chip="integratedNotification"
        :image-url="url(true, `/assets/icon/${colorMode.value === 'light' ? 'alarm' : 'alarm_dark'}.svg`)"
        @click:chip="navigateTo('/my/alarm')"
      />
    </ClientOnly>
    <ClientOnly>
      <AButton
        v-if="user?.id"
        custom-class="p-0"
        button-variant="ghost"
        button-color="black"
        use-leading
        :image-url="url(true, `/assets/icon/${colorMode.value === 'light' ? 'search' : 'search_dark'}.svg`)"
        :image-size="24"
        @click="() => navigateTo('/search')"
      />
    </ClientOnly>
  </div>
</template>
